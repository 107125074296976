import React from "react"
import SEO from "../components/seo"
import "../components/layout.css"
import Layout from "../components/layout"
import GuaranteeBadge from "../components/guarantee-badge"

const ReturnPolicy = () => {
  return (
    <Layout>
      <SEO
        title="Return Policy"
        description="Find out about our return policy."
      />
      <div className="w-full h-screen map md:p-8">
        <GuaranteeBadge type="alt" />
      </div>
    </Layout>
  )
}

export default ReturnPolicy

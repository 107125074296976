import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img, { FluidObject } from "gatsby-image"

const GuaranteeBadge = ({ type }) => {
  const data = useStaticQuery(graphql`
    query BadeQuery {
      image: file(relativePath: { eq: "money-back.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  if (type === "alt") {
    return (
      <section className="min-w-full font-sans my-16">
        <div className="mx-auto flex flex-col w-3/4 items-center">
          <div className="pl-6">
            <h1 className="text-center">30 Day Money Back Guarantee</h1>
            <p className="text-sm">
              We are convinced that the value of our lists is worth its purchase
              price, however, we understand that it is not for everyone. If you
              didn't find value in the product or feel dissatified in any way,
              contact us by email within 30 days of your purchase and we will
              refund you - no questions asked.
            </p>
            <p className="text-sm text-center italic">
              This makes your purchase completely risk-free
            </p>
            <p className="text-center">
              For more information reach out to us by e-mail at:
              <span> hey@theinfluencerlist.co </span>
            </p>
          </div>
          <div className="w-1/4">
            <Img
              fluid={data.image.childImageSharp.fluid}
              alt={`list example`}
            />
          </div>
        </div>
      </section>
    )
  }
  return (
    <section className="min-w-full font-sans my-16">
      <div className="mx-auto flex flex-col md:flex-row w-3/4 items-center">
        <div className="w-1/2">
          <Img fluid={data.image.childImageSharp.fluid} alt={`list example`} />
        </div>
        <div className="pl-6">
          <h3 className="text-center">30 Day Money Back Guarantee</h3>
          <p className="text-sm">
            We are convinced that the value of our lists is worth its purchase
            price, however, we understand that it is not for everyone. If you
            didn't find value in the product or feel dissatified in any way,
            contact us by email within 30 days of your purchase and we will
            refund you - no questions asked.
          </p>
          <p className="text-sm text-center italic">
            This makes your purchase completely risk-free
          </p>
        </div>
      </div>
    </section>
  )
}

export default GuaranteeBadge
